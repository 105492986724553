import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" 
          transform="translate(0.000000,466.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
       <path d="M3008 3183 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2492 3125 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2928 3033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3025 3030 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3133 2963 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M2781 2543 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M2728 2293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2888 2273 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2548 2153 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2385 2140 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2411 1674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4713 1682 c-34 -3 -43 -7 -43 -22 0 -33 -60 -286 -85 -361 -14 -40
-25 -76 -25 -81 0 -5 21 -8 48 -6 45 3 47 4 50 36 4 36 22 34 22 -2 0 -45 38
-58 89 -32 55 29 122 210 107 289 -13 71 -71 85 -122 30 -13 -14 -27 -23 -30
-20 -3 3 6 41 20 83 14 43 23 80 18 84 -4 3 -26 4 -49 2z m54 -251 c-6 -74
-25 -115 -55 -119 -32 -5 -37 20 -17 81 21 66 41 97 62 97 13 0 15 -9 10 -59z"/>
<path d="M4145 1654 c-65 -33 -105 -96 -105 -162 0 -32 6 -45 35 -70 19 -18
39 -32 44 -32 16 0 42 -50 36 -70 -10 -30 -40 -43 -56 -24 -10 12 -10 20 -1
35 16 25 16 26 -15 33 -57 15 -104 -31 -103 -103 1 -34 17 -54 57 -70 35 -14
123 -7 123 10 0 5 6 9 14 9 40 0 106 102 106 166 0 44 -17 70 -62 93 -63 32
-81 68 -58 112 14 25 70 27 70 3 0 -9 -7 -22 -17 -29 -26 -20 -9 -45 31 -45
69 0 104 97 50 139 -33 26 -105 28 -149 5z"/>
<path d="M4506 1557 c-2 -7 -11 -54 -20 -105 -19 -104 -32 -132 -60 -132 -17
0 -18 5 -12 49 4 27 15 75 26 107 11 31 20 61 20 66 0 4 -22 8 -49 8 l-48 0
-6 -52 c-4 -29 -14 -80 -23 -113 -18 -66 -19 -136 -4 -165 14 -26 61 -34 103
-17 54 23 91 87 131 228 19 68 34 124 33 125 -1 1 -21 4 -45 7 -29 3 -44 1
-46 -6z"/>
<path d="M3001 1334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1931 1264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1321 1046 c-20 -24 -9 -50 27 -65 19 -9 32 -22 32 -33 0 -24 -23
-33 -49 -18 -17 8 -21 8 -21 -3 0 -18 39 -26 68 -16 16 6 22 17 22 37 0 22 -7
31 -32 43 -18 8 -35 22 -36 32 -3 15 2 18 27 15 17 -1 32 2 33 7 5 17 -58 17
-71 1z"/>
<path d="M1439 1056 c-6 -6 -2 -85 6 -122 6 -26 39 -36 70 -22 17 8 24 21 29
55 9 65 7 93 -4 93 -5 0 -10 -24 -10 -53 0 -63 -17 -92 -48 -83 -17 6 -22 18
-27 64 -8 70 -9 75 -16 68z"/>
<path d="M1590 984 l0 -75 41 3 c49 4 71 27 52 56 -6 10 -9 31 -6 46 7 33 -8
46 -54 46 l-33 0 0 -76z m75 36 c0 -7 -10 -17 -23 -21 -25 -9 -39 8 -28 36 6
18 51 5 51 -15z m5 -64 c0 -23 -16 -36 -46 -36 -9 0 -14 11 -14 30 0 27 3 30
30 30 25 0 30 -4 30 -24z"/>
<path d="M1733 1053 c-27 -10 -14 -53 22 -74 36 -22 45 -46 21 -55 -8 -3 -24
-1 -35 6 -17 9 -21 8 -21 -5 0 -8 7 -16 15 -16 8 -1 21 -2 28 -3 26 -4 47 15
47 43 0 22 -7 31 -30 41 -17 7 -33 17 -36 22 -10 15 15 36 36 31 11 -3 20 0
20 6 0 11 -44 14 -67 4z"/>
<path d="M1856 1038 c-39 -55 0 -142 57 -131 15 3 27 10 27 15 0 5 -9 6 -21 3
-50 -16 -83 77 -38 108 11 8 29 13 40 10 10 -3 19 0 19 6 0 6 -15 11 -34 11
-25 0 -39 -7 -50 -22z"/>
<path d="M1980 985 c0 -43 4 -75 10 -75 6 0 10 14 10 30 0 20 5 30 15 30 8 0
21 -13 30 -30 9 -16 20 -30 25 -30 14 0 13 7 -7 36 -16 25 -16 27 0 36 23 12
22 55 -2 68 -11 5 -33 10 -50 10 l-31 0 0 -75z m78 27 c-2 -10 -15 -18 -31
-20 -23 -3 -27 0 -27 22 0 13 6 26 13 29 19 7 49 -13 45 -31z"/>
<path d="M2119 1018 c-3 -90 -2 -103 7 -101 13 4 17 143 4 143 -5 0 -11 -19
-11 -42z"/>
<path d="M2180 985 l0 -75 34 0 c52 0 66 9 66 41 0 21 -5 29 -17 29 -13 0 -14
3 -5 12 17 17 15 55 -4 62 -9 3 -29 6 -45 6 l-29 0 0 -75z m70 34 c0 -10 -3
-19 -7 -20 -5 0 -16 -2 -25 -4 -13 -2 -18 3 -18 20 0 24 16 35 38 27 6 -2 12
-13 12 -23z m-6 -45 c35 -13 12 -54 -30 -54 -9 0 -14 11 -14 30 0 30 11 37 44
24z"/>
<path d="M2315 1008 c4 -105 0 -98 48 -97 42 2 42 2 7 8 -28 5 -34 10 -35 34
-2 24 2 27 27 27 17 0 27 4 23 10 -4 6 -10 8 -15 5 -15 -9 -40 7 -40 26 0 15
7 19 35 19 19 0 35 5 35 10 0 6 -20 10 -44 10 l-43 0 2 -52z"/>
<path d="M2535 1046 c-58 -25 -75 -118 -29 -155 29 -23 79 -28 90 -10 4 7 0 9
-14 5 -29 -10 -80 19 -88 49 -10 39 9 78 46 97 30 15 36 15 62 2 44 -23 40
-103 -6 -104 -4 0 -5 15 -2 34 5 28 3 34 -13 39 -11 3 -28 -2 -39 -12 -16 -15
-18 -23 -14 -58 1 -6 22 -11 52 -11 46 -1 52 2 61 25 15 40 2 81 -31 98 -35
18 -37 18 -75 1z m45 -80 c0 -27 -28 -43 -36 -21 -9 21 3 45 21 45 9 0 15 -9
15 -24z"/>
<path d="M2747 1045 c-10 -7 -21 -31 -24 -53 -5 -34 -1 -45 21 -67 16 -16 33
-24 42 -21 9 3 22 6 30 6 8 0 14 4 14 9 0 5 -14 8 -32 7 -42 -1 -67 38 -52 81
8 23 16 29 47 31 21 2 35 7 32 13 -9 14 -57 10 -78 -6z"/>
<path d="M2860 985 c0 -43 4 -75 10 -75 6 0 10 16 10 35 0 33 2 35 35 35 33 0
35 -2 35 -35 0 -19 5 -35 10 -35 6 0 10 32 10 75 0 43 -4 75 -10 75 -5 0 -10
-15 -10 -33 0 -30 -2 -33 -32 -32 -30 0 -33 3 -36 33 -6 62 -22 31 -22 -43z"/>
<path d="M3017 1043 c-3 -10 -4 -43 -3 -73 l1 -55 45 -2 c41 -1 42 -1 10 5
-30 6 -35 11 -35 35 -1 23 3 27 28 27 15 0 26 4 23 9 -3 5 -14 7 -25 4 -11 -3
-21 2 -25 12 -9 24 1 35 34 35 16 0 32 5 35 10 4 6 -11 10 -39 10 -34 0 -46
-4 -49 -17z"/>
<path d="M3147 985 c-14 -41 -22 -75 -17 -75 5 0 12 9 15 20 4 14 15 20 35 20
20 0 31 -6 35 -20 3 -11 11 -20 16 -20 11 0 13 -7 -15 75 -13 38 -28 71 -34
73 -5 1 -21 -31 -35 -73z m47 23 c9 -35 8 -38 -15 -38 -15 0 -19 4 -15 16 3 9
6 22 6 30 0 22 17 16 24 -8z"/>
<path d="M3268 998 c-2 -35 -2 -69 0 -75 7 -21 21 -5 15 17 -5 20 -1 23 49 34
31 7 44 36 28 66 -8 15 -21 20 -49 20 l-39 0 -4 -62z m78 27 c4 -8 1 -22 -6
-30 -13 -15 -49 -21 -51 -7 0 4 -2 17 -5 30 -3 19 0 22 26 22 18 0 33 -6 36
-15z"/>
<path d="M3400 985 l0 -75 48 2 c43 1 44 2 10 5 -34 4 -38 7 -38 34 0 26 3 29
31 29 16 0 28 4 25 9 -3 4 -17 6 -31 3 -22 -4 -25 -1 -25 22 0 22 4 26 29 26
17 0 33 5 36 10 4 6 -12 10 -39 10 l-46 0 0 -75z"/>
<path d="M3530 1045 c-19 -23 -7 -49 33 -68 20 -10 37 -25 37 -32 0 -19 -35
-28 -60 -15 -16 8 -20 8 -20 -3 0 -16 45 -28 67 -20 23 9 35 41 23 63 -6 11
-16 20 -23 20 -7 0 -22 7 -33 15 -18 14 -18 16 -1 29 10 8 27 11 39 8 14 -3
19 -1 14 6 -10 17 -61 15 -76 -3z"/>
<path d="M3630 1050 c0 -5 11 -10 25 -10 24 0 25 -2 22 -57 -1 -32 2 -58 7
-58 5 0 9 26 8 58 0 54 1 57 24 57 13 0 24 5 24 10 0 6 -25 10 -55 10 -30 0
-55 -4 -55 -10z"/>
<path d="M3816 1028 c-8 -53 -2 -111 12 -116 9 -3 13 3 12 14 -4 37 0 44 30
44 28 0 50 22 50 49 0 20 -33 41 -65 41 -31 0 -35 -3 -39 -32z m58 12 c43 -16
29 -60 -19 -60 -15 0 -26 35 -18 56 6 17 5 17 37 4z"/>
<path d="M3950 985 c0 -43 4 -75 10 -75 6 0 10 14 10 30 0 19 5 30 14 30 8 0
23 -13 33 -30 9 -16 21 -30 25 -30 12 0 10 8 -8 35 -15 22 -15 26 0 40 36 37
9 75 -53 75 l-31 0 0 -75z m70 50 c6 -3 10 -15 10 -26 0 -14 -7 -19 -30 -19
-27 0 -30 3 -30 29 0 24 4 29 20 26 11 -2 25 -7 30 -10z"/>
<path d="M4090 983 c0 -42 3 -74 6 -71 8 9 11 148 2 148 -4 0 -8 -34 -8 -77z"/>
<path d="M4174 1052 c-34 -22 -43 -84 -18 -120 17 -24 77 -31 90 -11 5 9 -1
10 -21 5 -35 -9 -65 16 -65 56 0 37 34 71 62 62 14 -4 18 -2 14 5 -8 12 -45
14 -62 3z"/>
<path d="M4280 985 l0 -75 48 2 c43 1 44 2 10 5 -34 4 -38 7 -38 34 0 26 3 29
31 29 16 0 28 4 25 9 -3 4 -17 7 -31 6 -21 -3 -25 1 -25 21 0 21 5 24 35 24
19 0 35 5 35 10 0 6 -20 10 -45 10 l-45 0 0 -75z"/>
<path d="M4410 1004 c0 -34 4 -53 10 -49 6 3 10 28 10 56 0 27 -4 49 -10 49
-5 0 -10 -25 -10 -56z"/>
<path d="M4478 1053 c-2 -5 -1 -30 3 -58 l6 -50 1 58 c2 52 -2 69 -10 50z"/>
<path d="M4400 920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4475 920 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
